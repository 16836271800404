import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './ClientsCarousel.css'; 

// Importa dinamicamente as imagens
const clients = [
  { id: 1, src: require('../assets/images/client1.png'), alt: 'Cliente 1' },
  { id: 2, src: require('../assets/images/client2.png'), alt: 'Cliente 2' },
  { id: 3, src: require('../assets/images/client3.png'), alt: 'Cliente 3' },
  { id: 4, src: require('../assets/images/client4.png'), alt: 'Cliente 4' },
  { id: 5, src: require('../assets/images/client5.png'), alt: 'Cliente 5' },
  { id: 6, src: require('../assets/images/client6.png'), alt: 'Cliente 6' },
  { id: 7, src: require('../assets/images/client7.png'), alt: 'Cliente 7' },
  { id: 8, src: require('../assets/images/client8.png'), alt: 'Cliente 8' },
];

const ClientsCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="clients-carousel">
      <h2>Nossos Principais Clientes</h2>
      <Slider {...settings}>
        {clients.map(client => (
          <div key={client.id} className="client-slide">
            <img src={client.src} alt={client.alt} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ClientsCarousel;
