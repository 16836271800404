import React, { useState } from 'react';
import './Gallery.css';


const projectPhotos = [
  [
    'proj1.jpg',
    'proj2.jpg',
    'proj3.jpg',
    'proj4.jpg',
  ],
  [
    'proj5.jpg',
    'proj6.jpg',
    'proj7.jpg',
    'proj8.jpg',
  ],
  [
    'proj9.jpg',
    'proj10.jpg',
    'proj11.jpg',
    'proj12.jpg',
  ],
  [
    'proj13.jpg',
    'proj14.jpg',
    'proj15.jpg',
    'proj16.jpg',
  ],
];

const Gallery = () => {
  const [showAll, setShowAll] = useState(false);

  const visibleProjects = showAll ? projectPhotos : projectPhotos.slice(0, 1);

  const toggleView = () => {
    setShowAll((prevState) => !prevState);
  };

  return (
    <div id="gallery" className="gallery">
      <h2>Trabalhos Executados</h2>
      <div className="projects">
        {visibleProjects.map((photos, projectIndex) => (
          <div key={projectIndex} className="project">
            <div className="photos">
              {photos.map((photo, photoIndex) => (
                <img
                  key={photoIndex}
                  src={require(`../assets/images/${photo}`)}
                  alt={`Projeto ${projectIndex + 1} - Foto ${photoIndex + 1}`}
                  className="gallery-image"
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      <button className={`view-toggle ${showAll ? 'view-less' : 'view-more'}`} onClick={toggleView}>
        {showAll ? 'Veja Menos' : 'Veja Mais'}
      </button>
    </div>
  );
};

export default Gallery;
