import React from 'react';
import './Footer.css';
import logoFooter from '../assets/images/logo_Footer.png';
import qrCodeImage from '../assets/images/whatsapp-qrcode.png';

const socialLinks = [
  { name: "X", url: "https://twitter.com" },
  { name: "Instagram", url: "https://www.instagram.com/consultoriacerta.ti/" },
  { name: "Facebook", url: "https://facebook.com" },
  { name: "LinkedIn", url: "https://linkedin.com" },
];

const Footer = () => {
  return (
    <footer className="footer">
      <img src={logoFooter} alt="Logo Footer" className="footer-logo" />

      <div className="footer-content">
        <div className="footer-socials">
          {socialLinks.map((link, index) => (
            <a
              key={index}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className="footer-social-link"
            >
              {link.name}
            </a>
          ))}
        </div>

        <div className="footer-quote">
          <p className="quote-text">
            “Seja referência de qualidade. Algumas pessoas não estão acostumadas com um ambiente onde a excelência é esperada.”
          </p>
          <p className="quote-author">STEVE JOBS</p>
        </div>
      </div>

      <div className="footer-qr-container">
        <p className="qr-text">WhatsApp</p>
        <img src={qrCodeImage} alt="QR Code do WhatsApp" className="footer-qr" />
      </div>
    </footer>
  );
};

export default Footer;
